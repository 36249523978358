<template>
  <div class="LessonEntityDetails__Component">
    <b-switch @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
    }}</b-switch>
    <FieldInlineText label="שם יחידת הלימוד" @onChange="onChange" v-model="model.name" />
    <!-- <FieldInlineSelect label="סוג" @onChange="onChange" :filterable="false" :clearable="false" :options="[{ Value: '0', Text: 'תרגול' },
    { Value: 1, Text: 'וידאו' },
    { Value: 2, Text: 'סימולציה' },]" v-model="model.lessonType" /> -->
    <FieldInlineSelect label="סוג" @onChange="onChange" :filterable="true" :clearable="false" :optionsAsync="getLessonTypes"
    v-model="model.lessonType" />
    <FieldInlineText :type="'number'" label="סדר" @onChange="onChange" v-model="model.sortOrder" />
    <b-field label="פתיח">
      <ckeditor @input="onChange" :editor="editor" placeholder="'פתיח'" v-model="model.supportingMaterials" :config="editorConfig"></ckeditor>
    </b-field>
    <input type="color" @input="onChange" v-model="model.btnColor">
    <div style="margin-top: 15px;">
      <b-switch @input="onChange" v-model="model.isHidden">{{
        model.isHidden ? "מוסתר" : "לא מוסתר"
      }}</b-switch>
    </div>
  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';
import LessonService from '../services/LessonService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    // FieldInlineDate,
    FieldInlineSelect,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        name: null,
        lessonType: null,
        status: null,
        sortOrder: null,
        btnColor: null,
        isHidden: null
      },
      editor: ClassicEditor,
      editorConfig: {
        language: {
          ui: 'he',
        }
      }
    };
  },
  computed: {
    ...mapGetters('lesson', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth.course.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    if (this.model.lessonType === 0) {
      this.model.lessonType = { Text: 'תרגול', Value: 0 };
    }
    if (this.model.btnColor) {
      [this.model.btnColor] = this.model.btnColor?.split(';');
    }
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    getLessonTypes() {
      return () =>
        LessonService.getLessonTypes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: `${i.name}` }))
        );
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lesson', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style></style>
